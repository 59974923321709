import {
  Box,
  Button,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ActivityLogs from "../../../component/activityLog/Index";
import AnimatedContainer from "../../../component/animatedContainer/AnimatedContainer";
import MyLoader from "../../../component/loader/MyLoader";
import MyIcon from "../../../component/myIcon/Index";
import { FONTSIZES } from "../../../theme/FontSize";
import { URL } from "../../../utils/appURL";
import { useHttp } from "../../../utils/http";
const DetailRole = () => {
  const { get, put, deleting } = useHttp();
  const productId = useParams();
  const [data, setdata] = useState({
    name: "",
    username: "",
    password: "",
    activity_logs: [],
  });

  const [isDisable, setisDisable] = useState(true);
  const currentPage = 1;
  const navigate = useNavigate();
  const [showLog, setshowLog] = useState(false);
  const { onOpen, onClose, isOpen } = useDisclosure();

  const [isLoading, setisLoading] = useState(true);
  const [isError, setisError] = useState({
    name: "Required",
    username: "Required",
    password: "Required",
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSubmit = () => {
    put({
      url: URL.getListRoleURL + `/update/${productId.id}`,
      needAuth: true,
      showToast: true,
      data: {
        name: data.name,
      },
    }).then((res) => {
      navigate(-1);
    });
  };
  const fetchData = () => {
    get({
      url: URL.getListRoleURL + `/${productId.id}`,
      needAuth: true,
      showToast: false,
    }).then((res) => {
      setdata(res.data.data);
      setisLoading(false);
    });
  };
  const handleChange = (props) => {
    const { name, value } = props.target;
    if (name === "name") {
      setdata((prevState) => ({
        ...prevState,
        name: value,
      }));
      if (value === "") {
        setisError((prevState) => ({
          ...prevState,
          name: "Required",
        }));
      } else {
        setisError((prevState) => ({
          ...prevState,
          name: "Must be more than 4 character",
        }));
      }
    }
  };
  const clickEdit = () => {
    setisDisable(!isDisable);
  };
  const handleDelete = () => {
    deleting({
      url: URL.getListRoleURL + `/delete/${productId.id}`,
      needAuth: true,
      showToast: true,
    }).then((res) => {
      navigate(-1);
    });
  };

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  return (
    <AnimatedContainer>
      <Box flex={1} bg="white" p="4" rounded={10}>
        <HStack justifyContent={"space-between"} mb={5}>
          <Box>
            <Text fontFamily={"Semibold"} color="black">
              Role
            </Text>
          </Box>
          <HStack>
            <Button colorScheme="teal" onClick={() => setshowLog(true)}>
              <HStack>
                <MyIcon name={"notepad-text"} size={14} />
                <Text
                  fontSize={FONTSIZES.md}
                  fontFamily={"SemiBold"}
                  color="white"
                >
                  Show Log
                </Text>
              </HStack>
            </Button>
            <Button
              colorScheme="blue"
              minW={"100px"}
              bg={isDisable ? "blue.500" : "gray.500"}
              onClick={clickEdit}
            >
              <HStack alignItems={"center"}>
                <Text
                  fontFamily={"Semibold"}
                  color="white"
                  fontSize={FONTSIZES.md}
                >
                  {isDisable ? "Edit" : "Cancel"}
                </Text>
              </HStack>
            </Button>
            <Button
              colorScheme="red"
              minW={"100px"}
              bg={"red.500"}
              onClick={onOpen}
            >
              <HStack alignItems={"center"}>
                <Text
                  fontFamily={"Semibold"}
                  color="white"
                  fontSize={FONTSIZES.md}
                >
                  Delete
                </Text>
              </HStack>
            </Button>
            {/* <NavButton nav={-1} title="Back" /> */}
          </HStack>
        </HStack>
        {isLoading ? (
          <MyLoader />
        ) : (
          <Box borderWidth={1} borderRadius={5} p={5}>
            <Text fontFamily={"Semibold"}>Detail Role</Text>
            <HStack flex={1}>
              <Stack flex={3} py={4} spacing={5}>
                <HStack flex={1} spacing={10} alignItems={"center"}>
                  <Box flex={0.2}>
                    <Text>Name</Text>
                  </Box>
                  <Stack flex={0.7}>
                    <Input
                      value={data.name}
                      name="name"
                      isInvalid={data.name.length < 4}
                      readOnly={isDisable}
                      fontFamily={"Regular"}
                      onChange={(e) => handleChange(e)}
                    />
                    {data.name.length < 4 && (
                      <AnimatedContainer>
                        <Text
                          color="red.500"
                          fontSize={"xs"}
                          fontFamily={"Medium"}
                        >
                          {isError.name}
                        </Text>
                      </AnimatedContainer>
                    )}
                  </Stack>
                </HStack>
              </Stack>
            </HStack>
          </Box>
        )}

        {isDisable !== true && (
          <HStack py={10} justifyContent={"flex-end"}>
            <Button
              isDisabled={data.name.length < 2}
              onClick={handleSubmit}
              colorScheme="teal"
              minW={"100px"}
              bg={"teal.500"}
            >
              <HStack alignItems={"center"}>
                <Text
                  fontFamily={"Semibold"}
                  color="white"
                  fontSize={FONTSIZES.md}
                >
                  Save
                </Text>
              </HStack>
            </Button>
          </HStack>
        )}
      </Box>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={["xs", "sm", "md", "lg", "xl"]}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text fontFamily={"Regular"} fontSize={[12, 14, 16]}>
              Delete User {data.name}
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontFamily={"Regular"} fontSize={[12, 14, 16]}>
              Are you sure?
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button variant={"ghost"} mr={3} onClick={onClose}>
              <Text fontFamily={"Regular"} fontSize={[12, 14, 16]}>
                Close
              </Text>
            </Button>
            <Button colorScheme="red" onClick={handleDelete}>
              <Text fontFamily={"Regular"} fontSize={[12, 14, 16]}>
                Delete
              </Text>
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={showLog}
        onClose={() => setshowLog(false)}
        size={["xs", "sm", "md", "lg", "xl"]}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text fontFamily={"Regular"} fontSize={[12, 14, 16]}>
              Data log role : {data.transaction_no}
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {data.activity_logs.map((item) => {
              return <ActivityLogs item={item} />;
            })}
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </AnimatedContainer>
  );
};
export default DetailRole;
