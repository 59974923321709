import {
  Box,
  Button,
  HStack,
  Input,
  Stack,
  Switch,
  Text
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AnimatedContainer from "../../../component/animatedContainer/AnimatedContainer";
import { FONTSIZES } from "../../../theme/FontSize";
import { URL } from "../../../utils/appURL";
import { useHttp } from "../../../utils/http";
const AddUser = () => {
  const { get, post } = useHttp();
  const [data, setdata] = useState({ name: "", username: "", password: "" });
  const [selectedRole, setselectedRole] = useState(null);
  const [dataRole, setdataRole] = useState([]);

  const [isActive, setisActive] = useState(false);

  const isDisable = false;
  const currentPage = 1;
  const navigate = useNavigate();

  const [isError, setisError] = useState({
    name: "Required",
    username: "Required",
    password: "Required",
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSubmit = () => {
    post({
      url: URL.getListUserURL + "/store",
      needAuth: true,
      showToast: true,
      data: {
        name: data.name,
        role: selectedRole,
        username: data.username,
        password: data.password,
        is_active: isActive,
        address: data.address,
      },
    }).then((res) => {
      navigate(-1);
    });
  };
  const fetchData = () => {
    get({
      url: URL.getListRoleURL + `?page=${currentPage}`,
      needAuth: true,
      showToast: false,
    }).then((res) => {
      setdataRole(res.data.data.data);
    });
  };
  const handleChange = (props) => {
    const { name, value } = props.target;
    if (name === "password") {
      setdata((prevState) => ({
        ...prevState,
        password: value,
      }));
      if (value === "") {
        setisError((prevState) => ({
          ...prevState,
          password: "Required",
        }));
      } else {
        setisError((prevState) => ({
          ...prevState,
          password: "Minimum 8 character",
        }));
      }
    } else if (name === "name") {
      setdata((prevState) => ({
        ...prevState,
        name: value,
      }));
      if (value === "") {
        setisError((prevState) => ({
          ...prevState,
          name: "Required",
        }));
      } else {
        setisError((prevState) => ({
          ...prevState,
          name: "Must be more than 4 character",
        }));
      }
    } else if (name === "address") {
      setdata((prevState) => ({
        ...prevState,
        address: value,
      }));
    } else if (name === "username") {
      setdata((prevState) => ({
        ...prevState,
        username: value,
      }));
      if (value === "") {
        setisError((prevState) => ({
          ...prevState,
          username: "Required",
        }));
      } else {
        setisError((prevState) => ({
          ...prevState,
          username: "Must be more than 4 character",
        }));
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  return (
    <AnimatedContainer>
      <Box flex={1} bg="white" p="4" rounded={10}>
        <HStack justifyContent={"space-between"} mb={5}>
          <Box>
            <Text fontFamily={"Semibold"} color="black">
              User
            </Text>
          </Box>
        </HStack>
        <Box borderWidth={1} borderRadius={5} p={5}>
          <Text fontFamily={"Semibold"}>Detail User</Text>
          <HStack flex={1}>
            <Stack flex={3} py={4} spacing={5}>
              <HStack flex={1} spacing={10} alignItems={"center"}>
                <Box flex={0.2}>
                  <Text>Name</Text>
                </Box>
                <Stack flex={0.7}>
                  <Input
                    name="name"
                    isInvalid={data.name.length < 4}
                    readOnly={isDisable}
                    fontFamily={"Regular"}
                    onChange={(e) => handleChange(e)}
                  />
                  {data.name.length < 4 && (
                    <AnimatedContainer>
                      <Text
                        color="red.500"
                        fontSize={"xs"}
                        fontFamily={"Medium"}
                      >
                        {isError.name}
                      </Text>
                    </AnimatedContainer>
                  )}
                </Stack>
              </HStack>
              <HStack flex={1} spacing={10} alignItems={"center"}>
                <Box flex={0.2}>
                  <Text>Address</Text>
                </Box>
                <Stack flex={0.7}>
                  <Input
                    name="address"
                    readOnly={isDisable}
                    fontFamily={"Regular"}
                    onChange={(e) => handleChange(e)}
                  />
                </Stack>
              </HStack>
              <HStack flex={1} spacing={10} alignItems={"center"}>
                <Box flex={0.2}>
                  <Text>Role</Text>
                </Box>
                <Box flex={0.7}>
                  <Select
                    isReadOnly={isDisable}
                    value={{
                      value: selectedRole,
                      label: selectedRole,
                    }}
                    onChange={(e) => setselectedRole(e.value)}
                    w={500}
                    tagVariant="solid"
                    options={dataRole.map((item) => ({
                      value: item.name,
                      label: item.name,
                    }))}
                  />
                </Box>
              </HStack>
              <HStack flex={1} spacing={10} alignItems={"center"}>
                <Box flex={0.2}>
                  <Text>Username</Text>
                </Box>
                <Stack flex={0.7}>
                  <Input
                    name="username"
                    isInvalid={data.username.length < 4}
                    readOnly={isDisable}
                    fontFamily={"Regular"}
                    onChange={(e) => handleChange(e)}
                  />
                  {data.username.length < 4 && (
                    <AnimatedContainer>
                      <Text
                        color="red.500"
                        fontSize={"xs"}
                        fontFamily={"Medium"}
                      >
                        {isError.username}
                      </Text>
                    </AnimatedContainer>
                  )}
                </Stack>
              </HStack>
              <HStack flex={1} spacing={10} alignItems={"center"}>
                <Box flex={0.2}>
                  <Text>Password</Text>
                </Box>
                <Stack flex={0.7}>
                  <Input
                    name="password"
                    isInvalid={data.password.length < 8}
                    readOnly={isDisable}
                    fontFamily={"Regular"}
                    onChange={(e) => handleChange(e)}
                  />
                  {data.password.length < 8 && (
                    <AnimatedContainer>
                      <Text
                        color="red.500"
                        fontSize={"xs"}
                        fontFamily={"Medium"}
                      >
                        {isError.password}
                      </Text>
                    </AnimatedContainer>
                  )}
                </Stack>
              </HStack>

              <HStack flex={1} spacing={10} alignItems={"center"}>
                <Box flex={0.2}>
                  <Text>Active</Text>
                </Box>
                <Box flex={0.7}>
                  <Switch
                    readOnly={isDisable}
                    onChange={() => setisActive(!isActive)}
                    isChecked={isActive == 1 ? true : false}
                  />
                </Box>
              </HStack>
            </Stack>
          </HStack>
        </Box>

        {isDisable !== true && (
          <HStack py={10} justifyContent={"flex-end"}>
            <Button
              isDisabled={
                data.username.length < 4 ||
                data.password.length < 8 ||
                data.name.length < 4 ||
                selectedRole === null
              }
              onClick={handleSubmit}
              colorScheme="teal"
              minW={"100px"}
              bg={"teal.500"}
            >
              <HStack alignItems={"center"}>
                <Text
                  fontFamily={"Semibold"}
                  color="white"
                  fontSize={FONTSIZES.md}
                >
                  Save
                </Text>
              </HStack>
            </Button>
          </HStack>
        )}
      </Box>
    </AnimatedContainer>
  );
};
export default AddUser;
