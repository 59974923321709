import {
  Box,
  Button,
  Center,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  Stack,
  Text,
} from "@chakra-ui/react";
import * as React from "react";
import ICChevronLeft from "../../../assets/icons/ICChevronLeft";
import ICChevronRight from "../../../assets/icons/ICChevronRight";
// import ICSearch from "../../../assets/icons/ICSearch";

import { Search2Icon } from "@chakra-ui/icons";
import {
  Body,
  Cell,
  Header,
  HeaderCell,
  HeaderRow,
  Row,
  Table,
} from "@table-library/react-table-library";
import {
  DEFAULT_OPTIONS,
  getTheme,
} from "@table-library/react-table-library/chakra-ui";
import { usePagination } from "@table-library/react-table-library/pagination";
import { useTheme } from "@table-library/react-table-library/theme";
import { useEffect, useState } from "react";
import Lottie from "react-lottie";
import { useNavigate } from "react-router-dom";
import emptyLotie from "../../../assets/lotties/empty.json";
import MyLoader from "../../../component/loader/MyLoader";
import { URL } from "../../../utils/appURL";
import { useHttp } from "../../../utils/http";

const TableUser = () => {
  const { get } = useHttp();
  const [data, setData] = useState({ nodes: [] });
  const [masterData, setmasterData] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [totalPages, settotalPages] = useState(1);
  const WW = window.innerWidth;
  const [sizesColumn, setSizesColumn] = useState("200px");
  const navigate = useNavigate();
  const chakraTheme = getTheme(DEFAULT_OPTIONS);
  const custom = useTheme({
    Table: `
      --data-table-library_grid-template-columns: ${
        WW < 770 ? "100px" : "100px"
      } ${sizesColumn}  ${sizesColumn}  ${sizesColumn} minmax(150px, 1fr);
    `,

    Row: `
      &:hover {
          background-color: #d2fbf2;
          cursor: pointer; /* Add this line to change cursor to pointer on hover */
        }`,
  });
  const theme = [chakraTheme, custom];
  const [search, setSearch] = React.useState("");
  const [isLoading, setisLoading] = useState(true);
  const pagination = usePagination(data, {
    state: {
      page: 0,
      size: 10,
    },
    onChange: onPaginationChange,
  });

  function onPaginationChange(action, state) {}

  const handleSearch = (event) => {
    if (event.key === "Enter") {
      setisLoading(true);
      const newArr = masterData.nodes.filter((item) =>
        item.name.toLowerCase().includes(event.target.value.toLowerCase())
      );
      if (search === "") {
        setData(masterData);
      } else {
        setData((prevState) => ({
          ...prevState,
          nodes: newArr,
        }));
      }
      pagination.fns.onSetPage(0);
      setisLoading(false);
    }
  };
  const handleChangeText = (event) => {
    setSearch(event.target.value);
  };

  const handleClick = (item) => {
    const url = `/user/${item.id}`;
    navigate(url);
  };
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      // pagination.fns.onSetPage(pagination.state.page + 1);
      setcurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      // pagination.fns.onSetPage(pagination.state.page - 1);
      setcurrentPage(currentPage - 1);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const delta = 3; // Jumlah halaman sebelum dan sesudah halaman saat ini yang akan ditampilkan
    const left = currentPage - delta;
    const right = currentPage + delta + 1;

    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === totalPages || (i >= left && i < right)) {
        pageNumbers.push(
          <Button
            size={["xs", "md"]}
            key={i}
            colorScheme="teal"
            variant={currentPage === i ? "solid" : "ghost"}
            onClick={() => setcurrentPage(i)}
          >
            <Text fontFamily="Semibold" fontSize={[10, 12, 14]}>
              {i}
            </Text>
          </Button>
        );
      } else if (i === left - 1 || i === right + 1) {
        pageNumbers.push(
          <Button
            size={["xs", "md"]}
            key={i}
            colorScheme="teal"
            variant="ghost"
            disabled
          >
            <Text fontFamily="Semibold" fontSize={[10, 12, 14]}>
              ...
            </Text>
          </Button>
        );
      }
    }

    return pageNumbers;
  };
  const PaginationNav = () => {
    return (
      <HStack justify="flex-end" spacing={1}>
        <IconButton
          size={["xs", "md"]}
          // is isDisabled={currentPage === 1}
          aria-label="previous page"
          icon={<ICChevronLeft color="black" size="18" />}
          colorScheme="teal"
          variant="ghost"
          isDisabled={currentPage === 1}
          onClick={handlePrevPage}
        />
        {renderPageNumbers()}
        <IconButton
          size={["xs", "md"]}
          // }
          aria-label="next page"
          icon={<ICChevronRight color="black" size="18" />}
          colorScheme="teal"
          variant="ghost"
          isDisabled={currentPage === totalPages}
          onClick={handleNextPage}
        />
      </HStack>
    );
  };

  const fetchData = (text) => {
    setisLoading(true);
    let searchData = text;
    if (searchData === undefined) {
      searchData = search;
    }

    get({
      url: URL.getListUserURL + `?search=${searchData}&page=${currentPage}`,
      needAuth: true,
      showToast: false,
    }).then((response) => {
      settotalPages(response.data.data.last_page);
      setData((prevState) => ({
        ...prevState,
        nodes: response.data.data.data,
      }));
      setmasterData({
        nodes: response.data.data.data,
      });
      setisLoading(false);
    });
  };

  setTimeout(() => {
    setisLoading(false);
  }, 700);
  useEffect(() => {
    fetchData();

    if (WW < 770) {
      setSizesColumn("200px");
    } else {
      setSizesColumn("30%");
    }
  }, [currentPage]);
  return (
    <>
      <Stack spacing={5} direction={"row"}>
        <></>

        <InputGroup flex={1}>
          <InputLeftAddon
            bg="white"
            borderRightWidth={0}
            pointerEvents="none"
            children={<Search2Icon color="black" fontSize={[10, 12, 14]} />}
          />
          <Input
            borderLeftWidth={0}
            bg={"white"}
            fontSize={[10, 12, 14]}
            placeholder="Search Data"
            fontFamily={"Regular"}
            value={search}
            onKeyUp={handleSearch}
            onChange={handleChangeText}
          />
        </InputGroup>
      </Stack>
      <br />
      <Box p={1} borderWidth="1px" borderRadius="lg">
        {isLoading ? (
          <MyLoader />
        ) : data.nodes?.length > 0 ? (
          <Table
            data={data}
            theme={theme}
            layout={{ custom: true, horizontalScroll: true }}
            pagination={pagination}
          >
            {(tableList) => (
              <>
                <Header>
                  <HeaderRow>
                    <HeaderCell>
                      <Text
                        fontSize={[10, 12, 14]}
                        fontFamily={"Bold"}
                        color={"black"}
                      >
                        Id
                      </Text>
                    </HeaderCell>
                    <HeaderCell>
                      <Text
                        fontSize={[10, 12, 14]}
                        fontFamily={"Bold"}
                        color={"black"}
                      >
                        Name
                      </Text>
                    </HeaderCell>
                    <HeaderCell>
                      <Text
                        fontSize={[10, 12, 14]}
                        fontFamily={"Bold"}
                        color={"black"}
                      >
                        Code
                      </Text>
                    </HeaderCell>
                    <HeaderCell>
                      <Text
                        fontSize={[10, 12, 14]}
                        fontFamily={"Bold"}
                        color={"black"}
                      >
                        Role
                      </Text>
                    </HeaderCell>

                    <HeaderCell>
                      <Text
                        fontSize={[10, 12, 14]}
                        fontFamily={"Bold"}
                        color={"black"}
                      >
                        Address
                      </Text>
                    </HeaderCell>
                  </HeaderRow>
                </Header>

                <Body>
                  {tableList.map((item, index) => (
                    <Row key={item.id} item={item}>
                      <Cell onClick={() => handleClick(item)}>
                        <Text
                          fontSize={[10, 12, 14]}
                          fontFamily={"Regular"}
                          color="black"
                        >
                          {item.id}
                        </Text>
                      </Cell>
                      <Cell onClick={() => handleClick(item)}>
                        <HStack>
                          <a href={`#/user/${item.id}`}>
                            <Text
                              as={"u"}
                              fontSize={[10, 12, 14]}
                              fontFamily={"Regular"}
                              color="black"
                            >
                              {item.name}
                            </Text>
                          </a>
                        </HStack>
                      </Cell>
                      <Cell onClick={() => handleClick(item)}>
                        <Text
                          fontSize={[10, 12, 14]}
                          fontFamily={"Regular"}
                          color="black"
                        >
                          {item.code}
                        </Text>
                      </Cell>
                      <Cell onClick={() => handleClick(item)}>
                        <Text
                          fontSize={[10, 12, 14]}
                          fontFamily={"Regular"}
                          color="black"
                        >
                          {item.person_access.roles[0]?.name}
                        </Text>
                      </Cell>

                      <Cell onClick={() => handleClick(item)}>
                        <Text
                          fontSize={[10, 12, 14]}
                          fontFamily={"Regular"}
                          color="black"
                        >
                          {item.address}
                        </Text>
                      </Cell>
                    </Row>
                  ))}
                </Body>
              </>
            )}
          </Table>
        ) : (
          <Stack mt={5}>
            <Center>
              <Text fontFamily={"Semibold"}>Data Not Found</Text>
            </Center>
            <Lottie
              options={{
                animationData: emptyLotie,
              }}
              width={"20vw"}
            />
          </Stack>
        )}
      </Box>
      <br />
      {data.nodes?.length > 0 && <PaginationNav />}

      <br />
    </>
  );
};

export default TableUser;
