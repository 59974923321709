import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Search2Icon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  HStack,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  SkeletonCircle,
  Stack,
  Text,
} from "@chakra-ui/react";
import {
  Body,
  Cell,
  Header,
  HeaderCell,
  HeaderRow,
  Row,
  Table,
} from "@table-library/react-table-library";
import ICChevronLeft from "../../../assets/icons/ICChevronLeft";
import ICChevronRight from "../../../assets/icons/ICChevronRight";
import emptyLotie from "../../../assets/lotties/empty.json";
import MyLoader from "../../../component/loader/MyLoader";
import { FONTSIZES } from "../../../theme/FontSize";

import {
  DEFAULT_OPTIONS,
  getTheme,
} from "@table-library/react-table-library/chakra-ui";
import { usePagination } from "@table-library/react-table-library/pagination";
import { useTheme } from "@table-library/react-table-library/theme";
import { CircleXIcon } from "lucide-react";
import Lottie from "react-lottie";

import { URL, baseURLImage } from "../../../utils/appURL";
import { useHttp } from "../../../utils/http";

const ProductTable = () => {
  const [currentPage, setcurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [masterData, setmasterData] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [search, setSearch] = useState("");

  const navigate = useNavigate();
  const { get } = useHttp();
  const WW = window.innerWidth;
  const chakraTheme = getTheme(DEFAULT_OPTIONS);
  const custom = useTheme({
    Table: `
      --data-table-library_grid-template-columns: ${
        WW < 770 ? "100px" : "100px"
      } ${"400px"}  ${"150px"} ${"100px"} ${"200px"} ${"400px"};
    `,

    Row: `
      &:hover {
          background-color: #d2fbf2;
          cursor: pointer; /* Add this line to change cursor to pointer on hover */
        }`,
  });
  const theme = [chakraTheme, custom];
  const pagination = usePagination(masterData, {
    state: {
      page: 0,
      size: 12,
    },
    onChange: {},
  });

  const handleSearch = (event) => {
    if (event.key === "Enter") {
      setcurrentPage(1);
      fetchData();
    }
  };

  const handleChangeText = (event) => {
    setSearch(event.target.value);
  };

  const handleClick = (item) => {
    const url = `/product/${item.id}`;
    navigate(url);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setcurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setcurrentPage(currentPage - 1);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const delta = 3;
    const left = currentPage - delta;
    const right = currentPage + delta + 1;

    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === totalPages || (i >= left && i < right)) {
        pageNumbers.push(
          <Button
            size={["xs", "md"]}
            key={i}
            colorScheme="teal"
            variant={currentPage === i ? "solid" : "ghost"}
            onClick={() => setcurrentPage(i)}
          >
            <Text fontFamily="Semibold" fontSize={FONTSIZES.md}>
              {i}
            </Text>
          </Button>
        );
      } else if (i === left - 1 || i === right + 1) {
        pageNumbers.push(
          <Button
            size={["xs", "md"]}
            key={i}
            colorScheme="teal"
            variant="ghost"
            disabled
          >
            <Text fontFamily="Semibold" fontSize={FONTSIZES.md}>
              ...
            </Text>
          </Button>
        );
      }
    }
    return pageNumbers;
  };

  const PaginationNav = () => {
    return (
      <HStack justify="flex-end" spacing={1}>
        <IconButton
          size={["xs", "md"]}
          aria-label="previous page"
          icon={<ICChevronLeft color="black" size="18" />}
          colorScheme="teal"
          variant="ghost"
          isDisabled={currentPage === 1}
          onClick={handlePrevPage}
        />
        {renderPageNumbers()}
        <IconButton
          size={["xs", "md"]}
          aria-label="next page"
          icon={<ICChevronRight color="black" size="18" />}
          colorScheme="teal"
          variant="ghost"
          isDisabled={currentPage === totalPages}
          onClick={handleNextPage}
        />
      </HStack>
    );
  };

  const fetchData = (keyWord) => {
    let searchData = keyWord;
    if (searchData === undefined) {
      searchData = search;
    }
    const apiUrl = `${URL.getListProductURL}?search=${searchData}&page=${currentPage}&with_paginate=1`;
    setisLoading(true);
    get({ url: apiUrl, needAuth: true, showToast: false })
      .then((response) => {
        setTotalPages(response.data.data.product.last_page);
        setmasterData(response.data.data.product.data);
        setisLoading(true);
      })
      .catch((error) => {
        setisLoading(true);
      });
  };

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  return (
    <>
      <Stack spacing={5} direction={"row"}>
        <InputGroup flex={1}>
          <InputLeftAddon
            bg="white"
            borderRightWidth={0}
            pointerEvents="none"
            children={<Search2Icon color="black" fontSize={FONTSIZES.md} />}
          />
          <Input
            borderRightRadius={0}
            borderLeftWidth={0}
            bg={"white"}
            fontSize={FONTSIZES.md}
            placeholder="Search Data"
            fontFamily={"Regular"}
            value={search}
            onKeyUp={handleSearch}
            onChange={handleChangeText}
          />
          <Box>
            <Button
              bg="red.500"
              colorScheme="red"
              borderLeftRadius={0}
              onClick={() => [setSearch(""), fetchData("")]}
            >
              <CircleXIcon color="white" fontSize={FONTSIZES.md} />
            </Button>
          </Box>
        </InputGroup>
      </Stack>
      <br />
      <Box p={1} borderWidth="1px" borderRadius="lg">
        {!isLoading ? (
          <MyLoader />
        ) : masterData.length > 0 ? (
          <Table
            data={{ nodes: masterData }}
            theme={theme}
            layout={{ custom: true, horizontalScroll: true }}
            pagination={pagination}
          >
            {(tableList) => (
              <>
                <Header>
                  <HeaderRow>
                    <HeaderCell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Bold"}
                        color={"black"}
                      >
                        Id
                      </Text>
                    </HeaderCell>
                    <HeaderCell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Bold"}
                        color={"black"}
                      >
                        Name
                      </Text>
                    </HeaderCell>
                    <HeaderCell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Bold"}
                        color={"black"}
                      >
                        Code
                      </Text>
                    </HeaderCell>
                    <HeaderCell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Bold"}
                        color={"black"}
                      >
                        Unit
                      </Text>
                    </HeaderCell>

                    <HeaderCell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Bold"}
                        color={"black"}
                      >
                        Category
                      </Text>
                    </HeaderCell>
                    <HeaderCell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Bold"}
                        color={"black"}
                      >
                        Sub Category
                      </Text>
                    </HeaderCell>
                  </HeaderRow>
                </Header>

                <Body>
                  {tableList.map((item, index) => (
                    <Row
                      key={item.id}
                      item={item}
                      onClick={() => handleClick(item)}
                    >
                      <Cell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Regular"}
                          color="black"
                        >
                          {item.id}
                        </Text>
                      </Cell>
                      <Cell>
                        <HStack>
                          <Image
                            boxSize="40px"
                            objectFit="cover"
                            rounded={"full"}
                            src={`${baseURLImage}/${item.image}`}
                            alt="logo"
                            fallback={<SkeletonCircle boxSize="40px" />}
                          />
                          <a onClick={() => handleClick(item)}>
                            <Text
                              as="u"
                              fontSize={FONTSIZES.md}
                              fontFamily={"Regular"}
                              color="black"
                            >
                              {item.name}
                            </Text>
                          </a>
                        </HStack>
                      </Cell>
                      <Cell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Regular"}
                          color="black"
                        >
                          {item.code}
                        </Text>
                      </Cell>
                      <Cell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Regular"}
                          color="black"
                        >
                          {item.unit.name}
                        </Text>
                      </Cell>
                      <Cell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Regular"}
                          color="black"
                        >
                          {item.product_category?.name}
                        </Text>
                      </Cell>
                      <Cell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Regular"}
                          color="black"
                        >
                          {item.product_subcategory?.name}
                        </Text>
                      </Cell>
                    </Row>
                  ))}
                </Body>
              </>
            )}
          </Table>
        ) : (
          <Stack mt={5}>
            <Center>
              <Text fontFamily={"Semibold"}>Data Not Found</Text>
            </Center>
            <Lottie
              options={{
                animationData: emptyLotie,
              }}
              width={"20vw"}
            />
          </Stack>
        )}
      </Box>
      <br />
      {totalPages > 0 && <PaginationNav />}
      <br />
    </>
  );
};

export default ProductTable;
