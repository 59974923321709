import {
  Box,
  Button,
  HStack,
  Input,
  InputGroup,
  InputRightAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AnimatedContainer from "../../../component/animatedContainer/AnimatedContainer";
import { URL } from "../../../utils/appURL";
import { useHttp } from "../../../utils/http";
const DetailPromotion = () => {
  const navigate = useNavigate();
  const { get, deleting } = useHttp();
  const id = useParams();

  const [dataDiscount, setdataDiscount] = useState([
    { product: { name: "", id: "" }, discount: 0 },
  ]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [masterData, setmasterData] = useState({ promotion_details: [] });

  const fetchData = () => {
    get({
      url: URL.getPromotionURL + `/${id.id}`,
      needAuth: true,
      showToast: false,
    }).then((res) => {
      setmasterData(res.data.data);
    });
  };
  const handleDelete = () => [
    deleting({
      url: URL.getPromotionURL + `/delete/${id.id}`,
      needAuth: true,
      showToast: true,
    }).then((res) => {
      onClose();
      navigate(-1);
    }),
  ];
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <AnimatedContainer>
      <Box flex={1} bg="white" p="4" rounded={10}>
        <HStack justifyContent={"space-between"} mb={5}>
          <Box>
            <Text fontFamily={"Semibold"} color="black">
              Promotion
            </Text>
          </Box>
          <Button
            isDisabled={masterData.status === "ended"}
            onClick={onOpen}
            colorScheme="red"
            minW={"100px"}
            bg={"red.500"}
          >
            <HStack alignItems={"center"}>
              <Text
                fontFamily={"Semibold"}
                color="white"
                fontSize={[10, 12, 14]}
              >
                Delete
              </Text>
            </HStack>
          </Button>
        </HStack>
        <Box borderWidth={1} borderRadius={5} p={5}>
          <HStack flex={1}>
            <Stack flex={3} py={4} spacing={5}>
              <HStack flex={1} spacing={10} alignItems={"center"}>
                <Box flex={0.2}>
                  <Text>Code</Text>
                </Box>
                <Box flex={0.7}>
                  <Input
                    isDisabled={true}
                    value={masterData?.code ? masterData.code : ""}
                  />
                </Box>
              </HStack>
              <HStack flex={1} spacing={10} alignItems={"center"}>
                <Box flex={0.2}>
                  <Text>Applies To</Text>
                </Box>
                <Box flex={0.7}>
                  <Input
                    isDisabled={true}
                    value={
                      masterData?.applies_to
                        ? masterData.applies_to.toUpperCase()
                        : ""
                    }
                  />
                </Box>
              </HStack>
              <HStack flex={1} spacing={10} alignItems={"center"}>
                <Box flex={0.2}>
                  <Text>Store</Text>
                </Box>
                <Box flex={0.7}>
                  <Input
                    isDisabled={true}
                    value={masterData?.store ? masterData.store.name : "All"}
                  />
                </Box>
              </HStack>

              <HStack flex={1} spacing={10}>
                <Box flex={0.2}>
                  <Text>Start Date</Text>
                </Box>
                <HStack flex={0.7}>
                  <Box flex={0.4} borderWidth={1} borderRadius={5}>
                    {/* <ReactDatePicker
                      showTimeInput
                      timeFormat="HH:mm"
                      value={moment(startDate).format("DD/MM/YYYY HH:mm")}
                      onChange={(dates) => {
                        setstartDate(dates);
                      }}
                      // withPortal
                      startDate={startDate}
                      endDate={endDate}
                      selected={startDate}

                      // selectsRange
                      // minDate={startDate}
                    /> */}
                    <Input
                      isDisabled={true}
                      value={masterData?.start_at ? masterData.start_at : ""}
                    />
                  </Box>
                  <Box flex={0.2} justifyContent={"flex-end"}>
                    <Text textAlign={"right"}>End Date</Text>
                  </Box>
                  <HStack flex={0.4} borderWidth={1} borderRadius={5}>
                    <Input
                      isDisabled={true}
                      value={
                        masterData?.expired_at ? masterData.expired_at : ""
                      }
                    />
                  </HStack>
                </HStack>
              </HStack>

              <HStack flex={1} spacing={10} alignItems={"center"}>
                <Box flex={0.2}>
                  <Text>Notes</Text>
                </Box>
                <Box flex={0.7}>
                  <Input
                    isDisabled={true}
                    value={masterData?.notes ? masterData.notes : ""}
                  />
                </Box>
              </HStack>
              <HStack flex={1} spacing={10} alignItems={"center"}>
                <Box flex={0.2}>
                  <Text>Status</Text>
                </Box>
                <Box flex={0.7}>
                  <Input
                    color={
                      masterData?.status === "ongoing"
                        ? "green"
                        : masterData?.status === "ended"
                        ? "red"
                        : "orange"
                    }
                    isDisabled={true}
                    value={
                      masterData?.status ? masterData.status.toUpperCase() : ""
                    }
                  />
                </Box>
              </HStack>
            </Stack>
          </HStack>
        </Box>

        <HStack
          spacing={10}
          mt="5"
          borderTopRadius={5}
          p="2"
          bg="teal.500"
          w="100%"
        >
          <HStack justifyContent="center" flex={1}>
            <Text textAlign={"center"} color="white" fontFamily={"Semibold"}>
              Product
            </Text>
          </HStack>
          <HStack justifyContent="center" flex={1}>
            <Text textAlign={"center"} color="white" fontFamily={"Semibold"}>
              Discount
            </Text>
          </HStack>
          <HStack justifyContent="center" flex={0.2}>
            <Text
              textAlign={"center"}
              color="white"
              fontFamily={"Semibold"}
            ></Text>
          </HStack>
        </HStack>

        <Box borderBottomRadius={5} borderWidth={1} py={2}>
          {masterData.promotion_details.map((item, index) => {
            return (
              <HStack
                borderBottomRadius={5}
                spacing={10}
                px={5}
                py={2}
                key={index}
                flex={1}
                bg="white"
              >
                <Box flex={1} alignItems={"center"}>
                  <Input isDisabled={true} value={item.product.name} />
                </Box>
                <Box flex={1} alignItems={"center"}>
                  <InputGroup flex={1}>
                    <NumberInput
                      isDisabled={true}
                      flex={1}
                      value={item.discount || 0.001}
                      min={0.001}
                      max={100}
                      onChange={(v) => {
                        const newVouchers = [...dataDiscount];
                        newVouchers[index] = {
                          ...newVouchers[index],
                          discount: v,
                        };
                        setdataDiscount(newVouchers);
                      }}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                    <InputRightAddon>%</InputRightAddon>
                  </InputGroup>
                </Box>
              </HStack>
            );
          })}
        </Box>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Confirmation</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Are you sure you want to delete this promotion? This action cannot
              be undone.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" onClick={handleDelete}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </AnimatedContainer>
  );
};
export default DetailPromotion;
