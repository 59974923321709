import {
  Box,
  Button,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  NumberInput,
  NumberInputField,
  Stack,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { AsyncSelect } from "chakra-react-select";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import AnimatedContainer from "../../../component/animatedContainer/AnimatedContainer";
import { FONTSIZES } from "../../../theme/FontSize";
import { URL, baseURL } from "../../../utils/appURL";
import { useHttp } from "../../../utils/http";
const AddVoucher = () => {
  const { get, post } = useHttp();
  const navigate = useNavigate();
  const [dataVoucher, setdataVoucher] = useState([]);

  const [selectedProduct, setselectedProduct] = useState({ id: 0 });

  const [startDate, setstartDate] = useState(new Date());
  const [endDate, setendDate] = useState(new Date());

  const [total_voucher, settotal_voucher] = useState(0);

  const [selectedCategory, setselectedCategory] = useState({ id: 0 });

  const [selectedSubCategory, setselectedSubCategory] = useState({ id: 0 });
  const [store, setstore] = useState({ name: "All", id: null });

  const [typeVoucher, settypeVoucher] = useState("Automatic");
  const [nominal, setnominal] = useState();
  const [notes, setnotes] = useState();

  const [discountType, setdiscountType] = useState("Cash");

  const dataApplies = [
    { title: "All", value: "all" },
    { title: "Product", value: "product" },
    { title: "Category", value: "category" },
    { title: "Sub Category", value: "subcategory" },
  ];
  const [selectedApplies, setselectedApplies] = useState("all");
  const [generateDisable, setGenerateDisable] = useState(true);

  const generateVoucher = () => {
    const params = {
      total_voucher: total_voucher,
    };
    post({
      url: URL.getListVoucherURL + `/generate-vouchers`,
      data: params,
      needAuth: true,
      showToast: true,
    }).then((res) => {
      const bes = res.data.data;
      const newData = bes.map((obj) => ({ ...obj, person_id: null }));
      setdataVoucher(newData);
    });
  };
  const fetchMember = async (input) => {
    let allData = [];
    let currentPage = 1;
    await get({
      url:
        URL.getListCustomerURL +
        `?search=${input}&page=${currentPage}&with_paginate=1`,
      needAuth: true,
      showToast: false,
    }).then((res) => {
      allData = res.data.data.data;
    });
    return allData;
  };
  const handleSubmit = () => {
    let params = {
      type: typeVoucher.toLowerCase(),
      store_id: store.id,
      applies_to: selectedApplies,
      discount_type: discountType.toLowerCase(),
      start_date: startDate,
      expired_date: endDate,
      total_voucher: total_voucher,
      nominal: nominal,
      notes: notes,
      details: dataVoucher,
    };
    if (selectedApplies === "product") {
      params = { ...params, product_id: selectedProduct.id };
    } else if (selectedApplies === "category") {
      params = { ...params, category_id: selectedCategory.id };
    } else if (selectedApplies === "subcategory") {
      params = { ...params, subcategory_id: selectedSubCategory.id };
    }
    post({
      url: URL.getListVoucherURL + `/store`,
      data: params,
      needAuth: true,
      showToast: true,
    }).then((res) => {
      navigate(-1);
    });
  };

  const fetchDataProduct = async (input) => {
    let allData = [];
    let currentPage = 1;
    await get({
      url:
        URL.getListProductURL +
        `?search=${input}&page=${currentPage}&with_paginate=1`,
      needAuth: true,
      showToast: false,
    }).then((res) => {
      allData = res.data.data.product.data;
    });
    return allData;
  };
  const fetchDataStore = async (input) => {
    let allData = [];
    let currentPage = 1;
    await get({
      url:
        URL.getListStoreURL +
        `?search=${input}&page=${currentPage}&with_paginate=1`,
      needAuth: true,
      showToast: false,
    }).then((res) => {
      allData = res.data.data.data;
    });
    return allData;
  };
  const fetchDataCategory = async (input) => {
    let allData = [];
    let currentPage = 1;
    await get({
      url:
        URL.getListProductCategoryURL +
        `?search=${input}&page=${currentPage}&with_paginate=1`,
      needAuth: true,
      showToast: false,
    }).then((res) => {
      allData = res.data.data.data;
    });
    return allData;
  };
  const fetchDataSubCategory = async (input) => {
    let allData = [];
    let currentPage = 1;
    await get({
      url:
        URL.getListProductSubCategoryURL +
        `?search=${input}&page=${currentPage}&with_paginate=1`,
      needAuth: true,
      showToast: false,
    }).then((res) => {
      allData = res.data.data.data;
    });
    return allData;
  };

  const addCustomerToVoucher = (props) => {
    const newVouchers = [...dataVoucher]; // Salin data voucher yang ada
    newVouchers[props.index] = {
      ...newVouchers[props.index],
      person_id: props.e.id,
      // atau sesuai dengan data pelanggan yang ingin ditambahkan
    };
    setdataVoucher(newVouchers); // Perbarui state data voucher
  };

  const loadOptions = (inputValue) => {
    return fetchDataProduct(inputValue);
  };
  const loadOptionsMember = (inputValue) => {
    return fetchMember(inputValue);
  };
  const loadOptionsStore = (inputValue) => {
    return fetchDataStore(inputValue);
  };
  const loadOptionsCategory = (inputValue) => {
    return fetchDataCategory(inputValue);
  };
  const loadOptionsSubCategory = (inputValue) => {
    return fetchDataSubCategory(inputValue);
  };

  const handleChangeType = (item) => {
    setselectedApplies("all");
    setstore({ name: "All", id: null });
    settypeVoucher(item);
    setnominal("");
    settotal_voucher("");
    setnotes("");
    setselectedProduct({ id: 0 });
    setselectedCategory({ id: 0 });
    setselectedSubCategory({ id: 0 });

    setdataVoucher([]);
  };
  const handleDiscountType = (item) => {
    setselectedApplies("all");
    setdiscountType(item);
    setstore({ name: "All", id: null });
    // settypeVoucher(item);
    setnominal("");
    settotal_voucher("");
    setnotes("");

    setselectedProduct({ id: 0 });
    setselectedCategory({ id: 0 });
    setselectedSubCategory({ id: 0 });

    setdataVoucher([]);
  };
  const handleTotalVoucher = (e) => {
    setdataVoucher([]);
    settotal_voucher(e);
    setTimeout(() => {
      if (typeVoucher === "Custom") {
        let a = [];
        for (let i = 0; i < e; i++) {
          a.push({ code: "", person_id: null });
        }
        setdataVoucher(a);
      }
    }, 100);
  };

  useEffect(() => {
    if (total_voucher < 1) {
      setGenerateDisable(true);
    } else if (nominal < 1) {
      setGenerateDisable(true);
    } else if (selectedApplies === "all") {
      setGenerateDisable(false);
    } else if (selectedApplies === "product") {
      if (selectedProduct.id === 0) {
        setGenerateDisable(true);
      } else {
        setGenerateDisable(false);
      }
    } else if (selectedApplies === "category") {
      if (selectedCategory.id === 0) {
        setGenerateDisable(true);
      } else {
        setGenerateDisable(false);
      }
    } else if (selectedApplies === "subcategory") {
      if (selectedSubCategory.id === 0) {
        setGenerateDisable(true);
      } else {
        setGenerateDisable(false);
      }
    }
  }, [
    total_voucher,
    nominal,
    selectedApplies,
    selectedCategory,
    selectedProduct,
    selectedSubCategory,
  ]);

  return (
    <AnimatedContainer>
      <Box flex={1} bg="white" p="4" rounded={10}>
        <HStack justifyContent={"space-between"} mb={5}>
          <Box>
            <Text fontFamily={"Semibold"} color="black">
              Voucher
            </Text>
          </Box>
        </HStack>
        <Box borderWidth={1} borderRadius={5} p={5}>
          <Text fontFamily={"Semibold"}>Detail Voucher</Text>
          <HStack flex={1}>
            <Stack flex={3} py={4} spacing={5}>
              <HStack flex={1} spacing={10} alignItems={"center"}>
                <Box flex={0.2}>
                  <Text>Type</Text>
                </Box>
                <HStack spacing={"5"} flex={0.7}>
                  {["Automatic", "Custom"].map((item, index) => {
                    return (
                      <Button
                        key={index}
                        colorScheme="teal"
                        onClick={() => handleChangeType(item)}
                        bg={item === typeVoucher ? "teal.500" : "gray.100"}
                        minW={"100px"}
                        fontSize={[10, 12, 14]}
                      >
                        <Text
                          color={item === typeVoucher ? "white" : "black"}
                          fontFamily={"Regular"}
                        >
                          {item}
                        </Text>
                      </Button>
                    );
                  })}
                </HStack>
              </HStack>
              <HStack flex={1} spacing={10} alignItems={"center"}>
                <Box flex={0.2}>
                  <Text>Discount Type</Text>
                </Box>
                <HStack spacing={"5"} flex={0.7}>
                  {["Cash", "Percentage"].map((item, index) => {
                    return (
                      <Button
                        key={index}
                        colorScheme="teal"
                        onClick={() => handleDiscountType(item)}
                        bg={item === discountType ? "teal.500" : "gray.100"}
                        minW={"100px"}
                        fontSize={[10, 12, 14]}
                      >
                        <Text
                          color={item === discountType ? "white" : "black"}
                          fontFamily={"Regular"}
                        >
                          {item}
                        </Text>
                      </Button>
                    );
                  })}
                </HStack>
              </HStack>
              <HStack flex={1} spacing={10} alignItems={"center"}>
                <Box flex={0.2}>
                  <Text>Applies To</Text>
                </Box>
                <HStack spacing={"5"} flex={0.7}>
                  {dataApplies.map((item, index) => {
                    return (
                      <Button
                        key={index}
                        colorScheme="teal"
                        onClick={() => setselectedApplies(item.value)}
                        bg={
                          item.value === selectedApplies
                            ? "teal.500"
                            : "gray.100"
                        }
                        minW={"100px"}
                        fontSize={[10, 12, 14]}
                      >
                        <Text
                          color={
                            item.value === selectedApplies ? "white" : "black"
                          }
                          fontFamily={"Regular"}
                        >
                          {item.title}
                        </Text>
                      </Button>
                    );
                  })}
                </HStack>
              </HStack>
              <HStack flex={1} spacing={10} alignItems={"center"}>
                <Box flex={0.2}>
                  <Text>Store</Text>
                </Box>
                <Box flex={0.7}>
                  <AsyncSelect
                    value={store}
                    getOptionLabel={(e) => e.name}
                    getOptionValue={(e) => e.id}
                    onChange={(e) => setstore(e)}
                    cacheOptions={false}
                    loadOptions={loadOptionsStore}
                    defaultOptions={false}
                  />
                </Box>
              </HStack>
              {selectedApplies !== "all" && (
                <HStack flex={1} spacing={10} alignItems={"center"}>
                  <Box flex={0.2}>
                    <Text>
                      {selectedApplies === "product"
                        ? "Product"
                        : selectedApplies === "category"
                        ? "Category"
                        : "Sub Category"}
                    </Text>
                  </Box>
                  <Box flex={0.7}>
                    {selectedApplies === "product" ? (
                      <AsyncSelect
                        key="product" // Add key prop here
                        getOptionLabel={(e) => e.name}
                        getOptionValue={(e) => e.id}
                        onChange={(e) => setselectedProduct(e)}
                        loadOptions={loadOptions}
                        defaultOptions={false}
                        value={selectedProduct}
                      />
                    ) : selectedApplies === "category" ? (
                      <AsyncSelect
                        key="category" // Add key prop here
                        getOptionLabel={(e) => e.name}
                        getOptionValue={(e) => e.id}
                        onChange={(e) => setselectedCategory(e)}
                        loadOptions={loadOptionsCategory}
                        defaultOptions={false}
                      />
                    ) : (
                      <AsyncSelect
                        key="sub-category" // Add key prop here
                        getOptionLabel={(e) => e.name}
                        getOptionValue={(e) => e.id}
                        onChange={(e) => setselectedSubCategory(e)}
                        loadOptions={loadOptionsSubCategory}
                        defaultOptions={false}
                      />
                    )}
                  </Box>
                </HStack>
              )}
              <HStack flex={1} spacing={10}>
                <Box flex={0.2}>
                  <Text>Start Date</Text>
                </Box>
                <HStack flex={0.7}>
                  <Box flex={0.4} borderWidth={1} p={2} borderRadius={5}>
                    <ReactDatePicker
                      value={moment(startDate).format("MM/DD/YYYY")}
                      onChange={(dates) => {
                        setstartDate(dates);
                      }}
                      // withPortal
                      startDate={startDate}
                      endDate={endDate}
                      // selectsRange
                      minDate={startDate}
                    />
                  </Box>
                  <Box flex={0.2} justifyContent={"flex-end"}>
                    <Text textAlign={"right"}>End Date</Text>
                  </Box>
                  <HStack flex={0.4} borderWidth={1} p={2} borderRadius={5}>
                    <ReactDatePicker
                      value={moment(endDate).format("MM/DD/YYYY")}
                      onChange={(dates) => {
                        setendDate(dates);
                      }}
                      // withPortal
                      startDate={startDate}
                      endDate={endDate}
                      // selectsRange
                      minDate={startDate}
                    />
                  </HStack>
                </HStack>
              </HStack>

              <HStack flex={1} spacing={10} alignItems={"center"}>
                <Box flex={0.2}>
                  <Text>Discount</Text>
                </Box>
                <Box flex={0.7}>
                  <InputGroup>
                    <InputLeftAddon>
                      {discountType === "Cash" ? "Rp" : "%"}
                    </InputLeftAddon>
                    <NumberInput
                      borderLeftRadius={0}
                      value={nominal}
                      onChange={(value) => setnominal(value)}
                      precision={0}
                      step={1}
                      min={0}
                      max={discountType === "Percentage" ? 100 : undefined}
                      fontFamily={"Regular"}
                    >
                      <NumberInputField
                        borderLeftRadius={0}
                        placeholder={discountType === "Cash" ? "Rp" : "%"}
                      />
                    </NumberInput>
                  </InputGroup>
                </Box>
              </HStack>
              <HStack flex={1} spacing={10} alignItems={"center"}>
                <Box flex={0.2}>
                  <Text>Total Voucher</Text>
                </Box>
                <Box flex={0.7}>
                  <Input
                    value={total_voucher}
                    type="number"
                    onChange={(e) => handleTotalVoucher(e.target.value)}
                    fontFamily={"Regular"}
                  />
                </Box>
              </HStack>
              <HStack flex={1} spacing={10} alignItems={"center"}>
                <Box flex={0.2}>
                  <Text>Notes</Text>
                </Box>
                <Box flex={0.7}>
                  <Input
                    value={notes}
                    onChange={(e) => setnotes(e.target.value)}
                    fontFamily={"Regular"}
                  />
                </Box>
              </HStack>
            </Stack>
          </HStack>
          {typeVoucher === "Automatic" && (
            <Button
              isDisabled={generateDisable}
              onClick={generateVoucher}
              colorScheme="teal"
              minW={"100px"}
              bg={"teal.500"}
            >
              <HStack alignItems={"center"}>
                <Text
                  fontFamily={"Semibold"}
                  color={"white"}
                  fontSize={[10, 12, 14]}
                >
                  Generate Voucher
                </Text>
              </HStack>
            </Button>
          )}
        </Box>

        <Box mt="5" borderTopRadius={5} p="2" bg="teal.500" w="100%">
          <Text textAlign={"center"} color="white" fontFamily={"Semibold"}>
            Voucher Code
          </Text>
        </Box>

        {dataVoucher.map((item, index) => {
          return (
            <HStack
              py={3}
              justifyContent={"space-between"}
              key={index}
              flex={1}
              bg={index % 2 !== 0 ? "teal.50" : "white"}
            >
              {typeVoucher === "Automatic" ? (
                <Box
                  justifyContent={"flex-end"}
                  flex={0.4}
                  py={3}
                  // bg={index % 2 !== 0 ? "teal.50" : "white"}
                  key={index}
                >
                  <Text textAlign={"center"} fontFamily={"Semibold"}>
                    {item.code}
                  </Text>
                </Box>
              ) : (
                <HStack h={100} flex={0.5}>
                  <Box flex={1}>
                    <Text
                      textAlign={"center"}
                      flex={0.5}
                      fontFamily={"Semibold"}
                    >
                      Code
                    </Text>
                    <Box h={2} />
                  </Box>
                  <VStack justifyContent={"center"} h={100} flex={1}>
                    <Input
                      h={80}
                      flex={0.5}
                      onChange={(e) =>
                        setdataVoucher((old) =>
                          old.map((oldItem, oldIndex) =>
                            oldIndex === index
                              ? { ...oldItem, code: e.target.value }
                              : oldItem
                          )
                        )
                      }
                      textAlign={"center"}
                    />
                    <Box h={2}>
                      {dataVoucher[index].code == "" ||
                      dataVoucher[index].code == null ? (
                        <Text
                          color={"red.500"}
                          fontSize={FONTSIZES.sm}
                          textAlign={"center"}
                          fontFamily={"Regular"}
                        >
                          Empty Voucher Code!
                        </Text>
                      ) : dataVoucher[index].code.length < 8 ? (
                        <Text
                          color={"red.500"}
                          fontSize={FONTSIZES.sm}
                          textAlign={"center"}
                          fontFamily={"Regular"}
                        >
                          Minimum 8 Character!
                        </Text>
                      ) : (
                        dataVoucher.findIndex(
                          (item2) =>
                            item2.code === dataVoucher[index].code &&
                            index !== dataVoucher.indexOf(item2)
                        ) !== -1 && (
                          <Text
                            color={"red.500"}
                            fontSize={FONTSIZES.sm}
                            textAlign={"center"}
                            fontFamily={"Regular"}
                          >
                            Duplicate Vocuher Code!
                          </Text>
                        )
                      )}
                    </Box>
                  </VStack>
                </HStack>
              )}
              <HStack flex={0.4} spacing={10} alignItems={"center"}>
                <Box flex={0.1}>
                  <Text>Member</Text>
                  <Box h={2} />
                </Box>
                <Box flex={0.9}>
                  <AsyncSelect
                    // value={store}
                    getOptionLabel={(e) => e.name}
                    getOptionValue={(e) => e.id}
                    onChange={(e) => addCustomerToVoucher({ e, index })}
                    cacheOptions={false}
                    loadOptions={loadOptionsMember}
                    defaultOptions={false}
                  />
                  <Box h={2} />
                </Box>
              </HStack>
            </HStack>
          );
        })}
        <HStack py={10} justifyContent={"flex-end"}>
          <Button
            isDisabled={
              nominal == "" ||
              nominal == null ||
              nominal < 1 ||
              dataVoucher.length < 1 ||
              dataVoucher.some(
                (item) => item.code == "" || item.code == null
              ) ||
              dataVoucher.some(
                (item, index) =>
                  dataVoucher.findIndex(
                    (item2) =>
                      item2.code === item.code &&
                      index !== dataVoucher.indexOf(item2)
                  ) !== -1
              ) ||
              dataVoucher.some((item) => item.code.length < 8)
            }
            onClick={handleSubmit}
            colorScheme="teal"
            minW={"100px"}
            bg={"teal.500"}
          >
            <HStack alignItems={"center"}>
              <Text
                fontFamily={"Semibold"}
                color="white"
                fontSize={[10, 12, 14]}
              >
                Save
              </Text>
            </HStack>
          </Button>
        </HStack>
      </Box>
    </AnimatedContainer>
  );
};
export default AddVoucher;
