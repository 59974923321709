import {
  Box,
  Button,
  Center,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  Stack,
  Text,
} from "@chakra-ui/react";
import * as React from "react";
import ICChevronLeft from "../../../assets/icons/ICChevronLeft";
import ICChevronRight from "../../../assets/icons/ICChevronRight";
// import ICSearch from "../../../assets/icons/ICSearch";

import { Search2Icon } from "@chakra-ui/icons";
import {
  Body,
  Cell,
  Header,
  HeaderCell,
  HeaderRow,
  Row,
  Table,
} from "@table-library/react-table-library";
import {
  DEFAULT_OPTIONS,
  getTheme,
} from "@table-library/react-table-library/chakra-ui";
import { usePagination } from "@table-library/react-table-library/pagination";
import { useTheme } from "@table-library/react-table-library/theme";
import { AsyncSelect } from "chakra-react-select";
import { useEffect, useState } from "react";
import Lottie from "react-lottie";
import { useNavigate } from "react-router-dom";
import emptyLotie from "../../../assets/lotties/empty.json";
import MyLoader from "../../../component/loader/MyLoader";
import { FONTSIZES } from "../../../theme/FontSize";
import { URL } from "../../../utils/appURL";
import { formattedCurrency } from "../../../utils/formatedCurrency";
import formatedDate from "../../../utils/formatedDate";
import { useHttp } from "../../../utils/http";

const OtherTable = () => {
  const { get } = useHttp();

  const [selectedStore, setselectedStore] = useState({ value: "", name: "" });
  const [search, setSearch] = React.useState("");
  const [data, setData] = useState({ nodes: [] });
  const masterData = [];
  const [currentPage, setcurrentPage] = useState(1);
  const [totalPages, settotalPages] = useState(1);
  const WW = window.innerWidth;
  const [sizesColumn, setSizesColumn] = useState("200px");

  const navigate = useNavigate();
  const chakraTheme = getTheme(DEFAULT_OPTIONS);
  const custom = useTheme({
    Table: `
      --data-table-library_grid-template-columns: ${
        WW < 770 ? "100px" : "100px"
      }   ${sizesColumn}${sizesColumn} ${sizesColumn} ${sizesColumn} ${sizesColumn};
    `,

    Row: `
      &:hover {
          background-color: #d2fbf2;
          cursor: pointer; /* Add this line to change cursor to pointer on hover */
        }`,
  });
  const theme = [chakraTheme, custom];
  const [isLoading, setisLoading] = useState(true);
  const pagination = usePagination(data, {
    state: {
      page: 0,
      size: 10,
    },
    onChange: onPaginationChange,
  });
  const fetchData = (text) => {
    setisLoading(true);
    let searchData = text;
    if (searchData === undefined) {
      searchData = search;
    }
    get({
      url:
        URL.getListOtherURL +
        `?store_id=${selectedStore.id}&search=${searchData}&page=${currentPage}`,
      needAuth: true,
      showToast: false,
    }).then((res) => {
      settotalPages(res.data.data.last_page);
      setData((prevState) => ({
        ...prevState,
        nodes: res.data.data.data,
      }));

      setisLoading(false);
    });
  };
  const fetchDataStore = async (input) => {
    let allData = [];
    let currentPage = 1;
    await get({
      url: URL.getListStoreURL + `?search=${input}&page=${currentPage}`,
      needAuth: true,
      showToast: false,
    }).then((res) => {
      setisLoading(false);
      allData = res.data.data.data;
    });
    return allData;
  };
  function onPaginationChange(action, state) {}

  const handleSearch = (event) => {
    if (event.key === "Enter") {
      setcurrentPage(1);
      setisLoading(true);
      fetchData(search);
      if (search === "") {
        setData(masterData);
      }
    }

    pagination.fns.onSetPage(0);

    setisLoading(false);
  };
  const handleChangeText = (event) => {
    setSearch(event.target.value);
  };

  const handleClick = (item) => {
    const url = `/stock-adjustment/${item.id}`;
    navigate(url);
  };
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      // pagination.fns.onSetPage(pagination.state.page + 1);
      setcurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      // pagination.fns.onSetPage(pagination.state.page - 1);
      setcurrentPage(currentPage - 1);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const delta = 3; // Jumlah halaman sebelum dan sesudah halaman saat ini yang akan ditampilkan
    const left = currentPage - delta;
    const right = currentPage + delta + 1;
    let lastPage = 0;

    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === totalPages || (i >= left && i < right)) {
        pageNumbers.push(
          <Button
            size={["xs", "md"]}
            key={i}
            colorScheme="teal"
            variant={currentPage === i ? "solid" : "ghost"}
            onClick={() => setcurrentPage(i)}
          >
            <Text fontFamily="Semibold" fontSize={FONTSIZES.md}>
              {i}
            </Text>
          </Button>
        );
      } else if (i === left - 1 || i === right + 1) {
        pageNumbers.push(
          <Button
            size={["xs", "md"]}
            key={i}
            colorScheme="teal"
            variant="ghost"
            disabled
          >
            <Text fontFamily="Semibold" fontSize={FONTSIZES.md}>
              ...
            </Text>
          </Button>
        );
      }
    }

    return pageNumbers;
  };
  const PaginationNav = () => {
    return (
      <HStack justify="flex-end" spacing={1}>
        <IconButton
          size={["xs", "md"]}
          // is isDisabled={currentPage === 1}
          aria-label="previous page"
          icon={<ICChevronLeft color="black" size="18" />}
          colorScheme="teal"
          variant="ghost"
          isDisabled={currentPage === 1}
          // onClick={() => pagination.fns.onSetPage(pagination.state.page - 1)}
          onClick={handlePrevPage}
        />
        {renderPageNumbers()}
        <IconButton
          size={["xs", "md"]}
          // isDisabled={
          //   pagination.state.page ===
          //   pagination.state.getTotalPages(product) - 1
          // }
          aria-label="next page"
          icon={<ICChevronRight color="black" size="18" />}
          colorScheme="teal"
          variant="ghost"
          isDisabled={currentPage === totalPages}
          // onClick={() => pagination.fns.onSetPage(pagination.state.page + 1)}
          onClick={handleNextPage}
        />
      </HStack>
    );
  };

  const handleReset = () => {
    setselectedStore({ value: "", name: "" });
    setSearch("");
  };

  const loadOptionsStore = (inputValue) => {
    return fetchDataStore(inputValue);
  };

  setTimeout(() => {
    setisLoading(false);
  }, 700);

  useEffect(() => {
    fetchData();

    if (WW < 770) {
      setSizesColumn("200px");
    } else {
      setSizesColumn("25%");
    }
  }, [WW, currentPage, selectedStore]);

  return (
    <>
      <Stack spacing={5} direction={"row"}>
        <Stack zIndex={2} w="20vw">
          <Text fontSize={FONTSIZES.md} fontFamily={"Semibold"}>
            Store
          </Text>
          <Box flex={0.7}>
            <AsyncSelect
              value={selectedStore.value}
              getOptionLabel={(e) => e.name}
              getOptionValue={(e) => e.id}
              onChange={(e) => setselectedStore(e)}
              cacheOptions={false}
              loadOptions={loadOptionsStore}
              defaultOptions={false}
            />
          </Box>
        </Stack>
        <Stack flex={1}>
          <Text fontSize={FONTSIZES.md} fontFamily={"Regular"} color="white">
            Store
          </Text>
          <InputGroup flex={1}>
            <InputLeftAddon
              bg="white"
              borderRightWidth={0}
              pointerEvents="none"
              children={<Search2Icon color="black" fontSize={FONTSIZES.md} />}
            />
            <Input
              borderLeftWidth={0}
              bg={"white"}
              fontSize={FONTSIZES.md}
              placeholder="Search Data"
              fontFamily={"Regular"}
              value={search}
              onKeyUp={handleSearch}
              onChange={handleChangeText}
            />
          </InputGroup>
        </Stack>
        <Stack flex={0.2}>
          <Text fontSize={FONTSIZES.md} fontFamily={"Regular"} color="white">
            Store
          </Text>
          <Button onClick={handleReset} colorScheme="red" bg={"red.500"}>
            <Text fontFamily={"Semibold"} fontSize={FONTSIZES.md} color="white">
              Reset
            </Text>
          </Button>
        </Stack>
      </Stack>
      <br />
      <Box p={1} borderWidth="1px" borderRadius="lg">
        {isLoading ? (
          <MyLoader />
        ) : data.nodes?.length > 0 ? (
          <Table
            data={data}
            theme={theme}
            layout={{ custom: true, horizontalScroll: true }}
            pagination={pagination}
          >
            {(tableList) => (
              <>
                <Header>
                  <HeaderRow>
                    <HeaderCell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Bold"}
                        color={"black"}
                      >
                        Id
                      </Text>
                    </HeaderCell>
                    <HeaderCell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Bold"}
                        color={"black"}
                      >
                        Transaction No
                      </Text>
                    </HeaderCell>
                    <HeaderCell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Bold"}
                        color={"black"}
                      >
                        Store
                      </Text>
                    </HeaderCell>
                    <HeaderCell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Bold"}
                        color={"black"}
                      >
                        Grand Total
                      </Text>
                    </HeaderCell>

                    <HeaderCell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Bold"}
                        color={"black"}
                      >
                        Type
                      </Text>
                    </HeaderCell>
                    <HeaderCell>
                      <Text
                        fontSize={FONTSIZES.md}
                        fontFamily={"Bold"}
                        color={"black"}
                      >
                        Date
                      </Text>
                    </HeaderCell>
                  </HeaderRow>
                </Header>

                <Body>
                  {tableList.map((item, index) => (
                    <Row key={item.id} item={item}>
                      <Cell onClick={() => handleClick(item)}>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Regular"}
                          color="black"
                        >
                          {item.id}
                        </Text>
                      </Cell>
                      <Cell onClick={() => handleClick(item)}>
                        <HStack>
                          <a href={`#/stock-adjustment/${item.id}`}>
                            <Text
                              as={"u"}
                              fontSize={FONTSIZES.md}
                              fontFamily={"Regular"}
                              color="black"
                            >
                              {item.transaction_no}
                            </Text>
                          </a>
                        </HStack>
                      </Cell>
                      <Cell onClick={() => handleClick(item)}>
                        <HStack>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {item.store?.name}
                          </Text>
                        </HStack>
                      </Cell>
                      <Cell onClick={() => handleClick(item)}>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Regular"}
                          color="black"
                        >
                          Rp {formattedCurrency(item.grand_total)}
                        </Text>
                      </Cell>
                      <Cell onClick={() => handleClick(item)}>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Regular"}
                          color="black"
                        >
                          {item.other_type.name}
                        </Text>
                      </Cell>
                      <Cell onClick={() => handleClick(item)}>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Regular"}
                          color="black"
                        >
                          {formatedDate(item.transaction_date)}
                        </Text>
                      </Cell>
                    </Row>
                  ))}
                </Body>
              </>
            )}
          </Table>
        ) : (
          <Stack mt={5}>
            <Center>
              <Text fontFamily={"Semibold"}>Data Not Found</Text>
            </Center>
            <Lottie
              options={{
                animationData: emptyLotie,
              }}
              width={"20vw"}
            />
          </Stack>
        )}
      </Box>
      <br />
      {data.nodes?.length > 0 && <PaginationNav />}

      <br />
    </>
  );
};

export default OtherTable;
