import { Box, Flex, Stack, Text } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import AnimatedContainer from "../../component/animatedContainer/AnimatedContainer";
import { FONTSIZES } from "../../theme/FontSize";
import BarChart from "../../component/chart/BarChartCustomer";
import moment from "moment";
import { useHttp } from "../../utils/http";
import { URL } from "../../utils/appURL";

const Dashboard = () => {
  const [dataChart, setdataChart] = useState({
    top_stores: [],
    top_products: [],
    top_customers: [],
  });
  const { get } = useHttp();
  const fetchData = useCallback(() => {
    // Get Dashboard Data
    get({
      url: URL.getDashboardDataURL,
      needAuth: true,
      showToast: false,
    }).then((response) => {
      const data = response.data.data;
      setdataChart(data);
    });
  }, [get]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <AnimatedContainer>
      <Box>
        <Flex
          gap={"4"}
          w={"100%"}
          mb={5}
          rounded={10}
          flexWrap={{ base: "wrap", md: "nowrap" }}
        >
          <Stack
            justifyContent={"space-between"}
            direction={"row"}
            bgGradient="linear(to-tr, teal.100,teal.400,teal.50)"
            rounded={10}
            p="4"
            w={{ base: "100%", md: "50%" }}
            mb={{ base: 4, md: 0 }}
          >
            <Stack flex={1} justifyContent={"space-between"}>
              <Stack flex={1} justifyContent={"space-between"}>
                <Text
                  fontSize={FONTSIZES.lg}
                  fontFamily={"Semibold"}
                  color={"white"}
                >
                  Top Stores {moment().format("MMMM")}
                </Text>
                <Text
                  fontSize={FONTSIZES.xl}
                  fontFamily={"Bold"}
                  color={"white"}
                >
                  {dataChart.top_stores[0]?.name}
                </Text>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            justifyContent={"space-between"}
            direction={"row"}
            bgGradient="linear(to-tr, yellow.100,yellow.400,yellow.50)"
            rounded={10}
            p="4"
            w={{ base: "100%", md: "50%" }}
            mb={{ base: 4, md: 0 }}
          >
            <Stack flex={1} justifyContent={"space-between"}>
              <Stack flex={1} justifyContent={"space-between"}>
                <Text
                  fontSize={FONTSIZES.lg}
                  fontFamily={"Semibold"}
                  color={"white"}
                >
                  Top Products {moment().format("MMMM")}
                </Text>
                <Text
                  fontSize={FONTSIZES.xl}
                  fontFamily={"Bold"}
                  color={"white"}
                >
                  {dataChart.top_products[0]?.name}
                </Text>
              </Stack>
            </Stack>
          </Stack>

          <Stack
            justifyContent={"space-between"}
            direction={"row"}
            bgGradient="linear(to-tr, blue.100,blue.400,blue.50)"
            rounded={10}
            p="4"
            w={{ base: "100%", md: "50%" }}
            mb={{ base: 4, md: 0 }}
          >
            <Stack flex={1} justifyContent={"space-between"}>
              <Stack flex={1} justifyContent={"space-between"}>
                <Text
                  fontSize={FONTSIZES.lg}
                  fontFamily={"Semibold"}
                  color={"white"}
                >
                  Top Customers {moment().format("MMMM")}
                </Text>
                <Text
                  fontSize={FONTSIZES.xl}
                  fontFamily={"Bold"}
                  color={"white"}
                >
                  {dataChart.top_customers[0]?.name}
                </Text>
              </Stack>
            </Stack>
          </Stack>
        </Flex>
        <Flex gap={"4"} rounded={10} flexWrap={{ base: "wrap", md: "nowrap" }}>
          <Box
            rounded={10}
            p="4"
            bg="white"
            w={{ base: "100%", md: "70%" }}
            mb={{ base: 4, md: 0 }}
          >
            <BarChart
              title={`Top 10 Stores ${moment().format("MMMM")}`}
              item={dataChart.top_stores}
            />
          </Box>
          <Box
            rounded={10}
            p="4"
            bg="white"
            w={{ base: "100%", md: "70%" }}
            mb={{ base: 4, md: 0 }}
          >
            <BarChart
              title={`Top 10 Products ${moment().format("MMMM")}`}
              item={dataChart.top_products}
            />
          </Box>
          <Box
            rounded={10}
            p="4"
            bg="white"
            w={{ base: "100%", md: "70%" }}
            mb={{ base: 4, md: 0 }}
          >
            <BarChart
              title={`Top 10 Customers ${moment().format("MMMM")}`}
              item={dataChart.top_customers}
            />
          </Box>
        </Flex>
      </Box>
    </AnimatedContainer>
  );
};

export default Dashboard;
