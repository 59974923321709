import {
  Box,
  Button,
  Center,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { AsyncSelect } from "chakra-react-select";
import { CircleX, DownloadCloudIcon, UploadCloud } from "lucide-react";
import moment from "moment";
import React, { useState } from "react";
import ReactDatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import AnimatedContainer from "../../../component/animatedContainer/AnimatedContainer";
import MyLoader from "../../../component/loader/MyLoader";
import { URL } from "../../../utils/appURL";
import { formattedCurrency } from "../../../utils/formatedCurrency";
import { useHttp } from "../../../utils/http";
const AddPromotion = () => {
  const { get, post } = useHttp();
  const { isOpen, onClose } = useDisclosure();
  const [fileName, setfileName] = useState("");
  const navigate = useNavigate();
  const [dataDiscount, setdataDiscount] = useState([
    { product: { name: "", id: "" }, discount: 0.001 },
  ]);

  const [startDate, setstartDate] = useState(new Date());
  const [endDate, setendDate] = useState(new Date());
  const toast = useToast();

  const [store, setstore] = useState({ name: "All", id: null });
  const [isLoading, setisLoading] = useState(false);

  const [notes, setnotes] = useState();

  const dataApplies = [
    { title: "All", value: "all" },
    { title: "Member", value: "member" },
  ];
  const [selectedApplies, setselectedApplies] = useState("all");

  const handleSubmit = () => {
    const newData = dataDiscount.map((item) => ({
      product_id: item.product.id,
      discount: item.discount,
    }));

    let params = {
      applies_to: selectedApplies,
      start_at: startDate,
      expired_at: endDate,
      notes: notes,
      details: newData,
    };
    if (store.id !== null) {
      params = { ...params, store_id: store.id };
    }
    post({
      url: URL.getPromotionURL + "/store",
      data: params,
      needAuth: true,
      showToast: true,
    }).then((res) => {
      navigate(-1);
    });
  };

  const fetchDataProduct = async (input = "") => {
    let allData = [];
    let currentPage = 1;
    await get({
      url:
        URL.getListProductURL +
        `?store_id=${
          store.id
        }&search=${input}&page=${currentPage}&with_paginate=1&is_inventory=${
          store.id !== null ? 1 : 0
        }`,
      needAuth: true,
      showToast: false,
    }).then((res) => {
      allData = res.data.data.product.data;
    });

    return allData;
  };

  const fetchDataStore = async (input) => {
    let allData = [{ name: "All", id: null }];
    let currentPage = 1;
    await get({
      url:
        URL.getListStoreURL +
        `?search=${input}&page=${currentPage}&with_paginate=1`,
      needAuth: true,
      showToast: false,
    }).then((res) => {
      allData = res.data.data.data;
    });
    return allData;
  };

  const loadOptions = (inputValue) => {
    return fetchDataProduct(inputValue);
  };

  const loadOptionsStore = (inputValue) => {
    return fetchDataStore(inputValue);
  };

  const handleDeleteRow = (index) => {
    setdataDiscount(dataDiscount.filter((_, i) => i !== index));
  };

  const getTemplate = async () => {
    await get({
      url: URL.getPromotionURL + "/template/download",
      needAuth: true,
      showToast: true,
    }).then((res) => {
      const responseData = res.data.data.url_download;

      window.open(responseData);
    });
  };

  const handleFileUpload = async (event) => {
    setisLoading(true);
    if (event?.target?.files?.length === 0) {
      return;
    }

    const selectedFile = event.target.files[0];
    const formData = new FormData();
    setfileName(selectedFile.name);
    formData.append("file", selectedFile, selectedFile.name);
    await post({
      url: URL.getPromotionURL + "/read-file",
      data: formData,
      needAuth: true,
      showToast: false,
    }).then((response) => {
      const data = response.data;
      if (!response.data.header.error && data.details.invalid.length === 0) {
        setstartDate(data.header.start_at);
        setendDate(data.header.expired_at);
        setnotes(data.header.notes);
        setstore(
          data.header.store
            ? { name: data.header.store.name, id: data.header.store.id }
            : { name: "All", id: null }
        );
        setdataDiscount(
          data.details.valid.map((item) => ({
            product: { name: item.product.name, id: item.product.id },
            discount: item.discount,
          }))
        );
      } else if (data.details.invalid.length > 0) {
        setstartDate(data.header.start_at);
        setendDate(data.header.expired_at);
        setnotes(data.header.notes);
        setstore(
          data.header.store
            ? { name: data.header.store.name, id: data.header.store.id }
            : { name: "All", id: null }
        );
        const newdataValid = data.details.valid.map((item) => ({
          product: { name: item.product.name, id: item.product.id },
          discount: item.discount,
          messages: item.messages,
        }));
        const newdataInvalid = data.details.invalid.map((item) => ({
          product: { name: item.product.name, id: item.product.id },
          discount: item.discount,
          messages: item.messages,
        }));
        setdataDiscount([...newdataInvalid, ...newdataValid]);
      } else {
        setfileName("");
        setstartDate(new Date());
        setendDate(new Date());
        setnotes("");
        setstore({ name: "All", id: null });
        setdataDiscount([
          {
            product: { name: "", id: null },
            discount: 0,
          },
        ]);
      }
      if (!response.data.header.error && data.details.invalid.length === 0) {
        toast({
          title: "Success",
          description: "",
          status: "success",
          duration: 1000,
          position: "top",
        });
        setisLoading(false);
      } else if (data.details.invalid.length > 0) {
        toast({
          isClosable: true,
          title: "Error",
          description:
            data.details.invalid.length > 0
              ? data.details.invalid[0].messages
              : JSON.stringify(response.data.header.messages),
          status: "error",
          duration: 2000,
          position: "top",
        });
        setisLoading(false);
      } else {
        toast({
          isClosable: true,
          title: "Error",
          description: JSON.stringify(response.data.header.messages),
          status: "error",
          duration: 2000,
          position: "top",
        });
        setisLoading(false);
      }
    });
  };

  const checkDateTime = () => {
    const startDateTime = new Date(startDate);
    const endDateTime = new Date(endDate);
    return (
      startDateTime.toDateString() === endDateTime.toDateString() &&
      startDateTime.getHours() === endDateTime.getHours() &&
      startDateTime.getMinutes() === endDateTime.getMinutes()
    );
  };
  const isStartDateBiggerThanEndDate = () => {
    const startDateTime = new Date(startDate);
    const endDateTime = new Date(endDate);
    return startDateTime > endDateTime;
  };
  const handleChangeStore = async (e) => {
    setstore(e);
    setdataDiscount([
      {
        product: { name: "", id: null },
        discount: 0,
      },
    ]);
  };

  return (
    <AnimatedContainer>
      {isLoading ? (
        <Box position={"absolute"}>
          <MyLoader />
        </Box>
      ) : (
        <Box flex={1} bg="white" p="4" rounded={10}>
          <HStack justifyContent={"space-between"} mb={5}>
            <Box>
              <Text fontFamily={"Semibold"} color="black">
                Promotion
              </Text>
            </Box>
            <HStack justifyContent={"space-between"}>
              <Button colorScheme="orange" onClick={getTemplate}>
                <HStack>
                  <Icon as={DownloadCloudIcon} />
                  <Text fontFamily={"Regular"} fontSize={[12, 14, 16]}>
                    Get Template
                  </Text>
                </HStack>
              </Button>
              <Button
                as="label"
                htmlFor="file-upload"
                cursor="pointer"
                colorScheme="blue"
              >
                <HStack>
                  <Icon as={UploadCloud} />
                  <Text
                    fontFamily={"Regular"}
                    fontSize={[12, 14, 16]}
                    noOfLines={1}
                    maxW="150px"
                  >
                    {fileName ? fileName : "Upload (CSV)"}
                  </Text>
                </HStack>
              </Button>
              <Input
                id="file-upload"
                type="file"
                accept=".csv"
                onChange={(e) => {
                  handleFileUpload(e);
                }}
                display="none"
              />
              <Button
                colorScheme="red"
                onClick={() => {
                  setdataDiscount([
                    { product: { name: "", id: "" }, discount: 0.001 },
                  ]);
                  setfileName("");
                  setstore({ name: "All", id: null });
                  document.getElementById("file-upload").value = "";
                }}
              >
                <Text fontFamily={"Regular"} fontSize={[12, 14, 16]}>
                  Clear All
                </Text>
              </Button>
            </HStack>
          </HStack>
          <Box borderWidth={1} borderRadius={5} p={5}>
            <HStack flex={1}>
              <Stack flex={3} py={4} spacing={5}>
                <HStack flex={1} spacing={10} alignItems={"center"}>
                  <Box flex={0.2}>
                    <Text>Applies To</Text>
                  </Box>

                  <HStack spacing={"5"} flex={0.7}>
                    {dataApplies.map((item, index) => {
                      return (
                        <Button
                          key={index}
                          colorScheme="teal"
                          onClick={() => setselectedApplies(item.value)}
                          bg={
                            item.value === selectedApplies
                              ? "teal.500"
                              : "gray.100"
                          }
                          minW={"100px"}
                          fontSize={[10, 12, 14]}
                        >
                          <Text
                            color={
                              item.value === selectedApplies ? "white" : "black"
                            }
                            fontFamily={"Regular"}
                          >
                            {item.title}
                          </Text>
                        </Button>
                      );
                    })}
                  </HStack>
                </HStack>
                <HStack flex={1} spacing={10} alignItems={"center"}>
                  <Box flex={0.2}>
                    <Text>Store</Text>
                  </Box>
                  <Box flex={0.7}>
                    <AsyncSelect
                      isDisabled={fileName.length > 0}
                      value={store}
                      getOptionLabel={(e) => e.name}
                      getOptionValue={(e) => e.id}
                      onChange={(e) => handleChangeStore(e)}
                      cacheOptions={false}
                      loadOptions={loadOptionsStore}
                      defaultOptions={false}
                    />
                  </Box>
                </HStack>

                <HStack flex={1} spacing={10}>
                  <Box flex={0.2}>
                    <Text>Start Date</Text>
                  </Box>
                  <HStack flex={0.7}>
                    <Box flex={0.4} borderWidth={1} p={0} borderRadius={5}>
                      <ReactDatePicker
                        customInput={<Input borderWidth={0} />}
                        disabled={fileName.length > 0}
                        showTimeInput
                        timeFormat="HH:mm"
                        value={moment(startDate).format("DD-MM-YYYY HH:mm")}
                        onChange={(dates) => {
                          setstartDate(dates);
                          setendDate(dates);
                        }}
                        // withPortal
                        startDate={startDate}
                        endDate={endDate}
                        selected={startDate}
                        // selectsRange
                        minDate={new Date()}
                      />
                    </Box>
                    <Box flex={0.2} justifyContent={"flex-end"}>
                      <Text textAlign={"right"}>End Date</Text>
                    </Box>
                    <HStack flex={0.4} borderWidth={1} p={0} borderRadius={5}>
                      <ReactDatePicker
                        customInput={<Input borderWidth={0} />}
                        disabled={fileName.length > 0}
                        showTimeInput
                        timeFormat="HH:mm"
                        value={moment(endDate).format("DD-MM-YYYY HH:mm")}
                        onChange={(dates) => {
                          setendDate(dates);
                        }}
                        // withPortal
                        startDate={startDate}
                        endDate={endDate}
                        selected={endDate}
                        minDate={startDate}
                      />
                    </HStack>
                  </HStack>
                </HStack>
                {checkDateTime() && (
                  <HStack flex={1} spacing={10} mt={-5}>
                    <Box flex={0.2}></Box>
                    <HStack flex={0.7}>
                      <Box flex={0.4} p={0} borderRadius={5}>
                        <Text color={"red"} fontSize={12} textAlign={"right"}>
                          {checkDateTime()
                            ? "Tanggal Mulai Dan Tanggal Selesai Tidak Boleh Sama!"
                            : "Tanggal Mulai Lebih Besar Daripada Tanggal Selesai!"}
                        </Text>
                      </Box>
                      <Box flex={0.2} justifyContent={"flex-end"}></Box>
                      <Box flex={0.4} p={0} borderRadius={5}>
                        <Text color={"red"} fontSize={12} textAlign={"right"}>
                          {checkDateTime()
                            ? "Tanggal Mulai Dan Tanggal Selesai Tidak Boleh Sama!"
                            : "Tanggal Mulai Lebih Besar Daripada Tanggal Selesai!"}
                        </Text>
                      </Box>
                    </HStack>
                  </HStack>
                )}
                {isStartDateBiggerThanEndDate() && (
                  <HStack flex={1} spacing={10} mt={-5}>
                    <Box flex={0.2}></Box>
                    <HStack flex={0.7}>
                      <Box flex={0.4} p={0} borderRadius={5}>
                        <Text color={"red"} fontSize={12} textAlign={"right"}>
                          {checkDateTime()
                            ? "Tanggal Mulai Dan Tanggal Selesai Tidak Boleh Sama!"
                            : "Tanggal Mulai Lebih Besar Daripada Tanggal Selesai!"}
                        </Text>
                      </Box>
                      <Box flex={0.2} justifyContent={"flex-end"}></Box>
                      <Box flex={0.4} p={0} borderRadius={5}>
                        <Text color={"red"} fontSize={12} textAlign={"right"}>
                          {checkDateTime()
                            ? "Tanggal Mulai Dan Tanggal Selesai Tidak Boleh Sama!"
                            : "Tanggal Mulai Lebih Besar Daripada Tanggal Selesai!"}
                        </Text>
                      </Box>
                    </HStack>
                  </HStack>
                )}

                <HStack flex={1} spacing={10} alignItems={"center"}>
                  <Box flex={0.2}>
                    <Text>Notes</Text>
                  </Box>
                  <Box flex={0.7}>
                    <Input
                      isDisabled={fileName.length > 0}
                      value={notes}
                      onChange={(e) => setnotes(e.target.value)}
                      fontFamily={"Regular"}
                    />
                  </Box>
                </HStack>
              </Stack>
            </HStack>
          </Box>

          <HStack
            spacing={10}
            mt="5"
            borderTopRadius={5}
            p="2"
            bg="teal.500"
            w="100%"
          >
            <HStack justifyContent={"center"} flex={2}>
              <Text textAlign={"center"} color="white" fontFamily={"Semibold"}>
                Product
              </Text>
            </HStack>
            {store.id !== null && (
              <HStack justifyContent={"center"} flex={1}>
                <Text
                  textAlign={"center"}
                  color="white"
                  fontFamily={"Semibold"}
                >
                  Price
                </Text>
              </HStack>
            )}
            <HStack justifyContent={"center"} flex={1}>
              <Text textAlign={"center"} color="white" fontFamily={"Semibold"}>
                Discount
              </Text>
            </HStack>
            {store.id !== null && (
              <HStack flex={1}>
                <Text
                  textAlign={"center"}
                  color="white"
                  fontFamily={"Semibold"}
                >
                  Price After Discount
                </Text>
              </HStack>
            )}
            <HStack flex={0.2}>
              <Text
                textAlign={"center"}
                color="white"
                fontFamily={"Semibold"}
              ></Text>
            </HStack>
          </HStack>

          <Box borderBottomRadius={5} borderWidth={1} py={2}>
            {dataDiscount.map((item, index) => {
              return (
                <VStack
                  alignItems={"flex-start"}
                  borderBottomRadius={5}
                  px={5}
                  py={2}
                  key={index}
                  bg="white"
                >
                  <HStack spacing={10} w={"100%"}>
                    <Box flex={2} alignItems={"center"}>
                      <AsyncSelect
                        isDisabled={fileName.length > 0}
                        cacheOptions={false}
                        key="product" // Add key prop here
                        getOptionLabel={(e) => e.name}
                        placeholder="Select Product"
                        getOptionValue={(e) => e.id}
                        onChange={(e) => {
                          const newVouchers = [...dataDiscount];
                          newVouchers[index] = {
                            ...newVouchers[index],
                            product: e,
                            discount: 0.0001,
                            priceAfterDiscount: 0.0001,
                          };
                          setdataDiscount(newVouchers);
                        }}
                        loadOptions={loadOptions}
                        defaultOptions={false}
                        value={item.product}
                      />
                    </Box>
                    {store.id !== null && (
                      <Box flex={1} alignItems={"center"}>
                        <InputGroup flex={1}>
                          <Input
                            isReadOnly={true}
                            flex={1}
                            value={
                              formattedCurrency(
                                dataDiscount[index]?.product?.pricelist?.price
                              ) || 0
                            }
                          />
                        </InputGroup>
                      </Box>
                    )}
                    <Box flex={1} alignItems={"center"}>
                      <InputGroup flex={1}>
                        <NumberInput
                          isDisabled={fileName.length > 0}
                          flex={1}
                          value={item.discount || 0.0001}
                          min={0.0001}
                          max={100}
                          onChange={(v) => {
                            const newVouchers = [...dataDiscount];
                            const priceDiscount =
                              (dataDiscount[index]?.product?.pricelist?.price *
                                v) /
                              100;
                            newVouchers[index] = {
                              ...newVouchers[index],
                              discount: v,
                              priceAfterDiscount:
                                dataDiscount[index]?.product?.pricelist?.price -
                                priceDiscount,
                            };
                            setdataDiscount(newVouchers);
                          }}
                        >
                          <NumberInputField />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                        <InputRightAddon>%</InputRightAddon>
                      </InputGroup>
                    </Box>
                    {store.id !== null && (
                      <Box flex={1} alignItems={"center"}>
                        <InputGroup flex={1}>
                          <Input
                            flex={1}
                            value={
                              formattedCurrency(
                                dataDiscount[index]?.priceAfterDiscount
                              ) || 0
                            }
                            onChange={(e) => {
                              const after = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              );
                              const newVouchers = [...dataDiscount];
                              newVouchers[index] = {
                                ...newVouchers[index],
                                discount:
                                  ((dataDiscount[index]?.product?.pricelist
                                    ?.price -
                                    after) /
                                    dataDiscount[index]?.product?.pricelist
                                      ?.price) *
                                  100,
                                priceAfterDiscount: after,
                              };
                              setdataDiscount(newVouchers);
                            }}
                          />
                        </InputGroup>
                      </Box>
                    )}
                    <Center flex={0.2} justifyContent={"center"}>
                      <Box>
                        <Button
                          isDisabled={
                            dataDiscount.length < 2 || fileName.length > 0
                          }
                          alignSelf={"center"}
                          onClick={() => handleDeleteRow(index)}
                          colorScheme="red"
                        >
                          <Icon as={CircleX} color={"white"} />
                        </Button>
                      </Box>
                    </Center>
                  </HStack>
                  <Text color="red.500" fontSize={12}>
                    {item?.messages}
                  </Text>
                </VStack>
              );
            })}
          </Box>
          <HStack py={10} justifyContent={"flex-end"}>
            <Button
              isDisabled={fileName.length > 0}
              onClick={() => {
                setdataDiscount([
                  ...dataDiscount,
                  { product: { name: "", id: "" }, discount: 0.001 },
                ]);
              }}
              colorScheme="orange"
              minW={"100px"}
              bg={"orange.500"}
            >
              <HStack alignItems={"center"}>
                <Text
                  fontFamily={"Semibold"}
                  color="white"
                  fontSize={[10, 12, 14]}
                >
                  Add Line
                </Text>
              </HStack>
            </Button>
            <Button
              isDisabled={
                dataDiscount.length < 1 ||
                dataDiscount.some(({ product: { name } }) => name === "") ||
                dataDiscount[0].messages ||
                checkDateTime() ||
                isStartDateBiggerThanEndDate()
              }
              onClick={handleSubmit}
              colorScheme="teal"
              minW={"100px"}
              bg={"teal.500"}
            >
              <HStack alignItems={"center"}>
                <Text
                  fontFamily={"Semibold"}
                  color="white"
                  fontSize={[10, 12, 14]}
                >
                  Save
                </Text>
              </HStack>
            </Button>
          </HStack>
        </Box>
      )}
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={["xs", "sm", "md", "lg", "xl"]}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text fontFamily={"Regular"} fontSize={[12, 14, 16]}>
              Import Discount
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* <Text fontFamily={"Regular"} fontSize={[12, 14, 16]}>
              Are you sure?
            </Text> */}
            <HStack justifyContent={"space-between"}>
              <Button colorScheme="orange" onClick={getTemplate}>
                <Text fontFamily={"Regular"} fontSize={[12, 14, 16]}>
                  Get Template
                </Text>
              </Button>
              <Button
                as="label"
                htmlFor="file-upload"
                cursor="pointer"
                colorScheme="blue"
              >
                <HStack>
                  <Icon as={UploadCloud} />
                  <Text
                    fontFamily={"Regular"}
                    fontSize={[12, 14, 16]}
                    noOfLines={1}
                    maxW="150px"
                  >
                    {fileName ? fileName : "Upload (CSV)"}
                  </Text>
                </HStack>
              </Button>
              <Input
                id="file-upload"
                type="file"
                accept=".csv"
                onChange={handleFileUpload}
                display="none"
              />
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </AnimatedContainer>
  );
};
export default AddPromotion;
